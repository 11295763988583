import antdEn from 'antd/lib/locale-provider/en_US';
import enMessages from '../locales/en-US.json';
import { flattenMessages } from '../index';

const EnLang = {
  messages: {
    ...flattenMessages(enMessages)
  },
  antd: antdEn,
  locale: 'en-US'
};
export default EnLang;
