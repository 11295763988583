import { memo, useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import {
  Users,
  Rss,
  Layers,
  Share2,
  HardDrive,
  Airplay,
  Codepen,
  Book,
  Tag,
  Shield,
} from "react-feather";
import LogoWeb from "../assets/enserv-logo.png";
import SmallLogoWeb from "../assets/enserv-small-logo.png";
import styled from "styled-components";
import IntlMessages from "components/IntlMessages";
import {
  LineChartOutlined,
  ThunderboltOutlined,
  WarningOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const defaultOpenKeys = ["sub1"];

const AdminMenus = ({ collapsed }) => {
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = useState([location.pathname]);

  useEffect(() => setCurrent([location.pathname]), [location.pathname]);

  function onMenuSelected(e) {
    const menu = e.key;
    if (location.pathname !== menu) {
      setCurrent(e.key);
      history.push(menu);
    }
  }

  return (
    <AdminSlider trigger={null} collapsible collapsed={collapsed}>
      <div className="app-logo d-flex justify-content-center align-items-center">
        {!collapsed && (
          <span>
            <img src={LogoWeb} style={{ height: "25px" }} alt="logo" />
          </span>
        )}
        {collapsed && (
          <span>
            <img src={SmallLogoWeb} style={{ height: "30px" }} alt="logo" />
          </span>
        )}
      </div>
      <Menu
        className="mt-3"
        onClick={onMenuSelected}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={current}
        mode="inline"
      >
        {/* MAIN */}
        <Menu.ItemGroup key="main_group" title={<IntlMessages id="main" />}>
          <Menu.Item
            key="/dashboard"
            icon={<Layers size={14} className="transform-bug" />}
          >
            <IntlMessages id="dashboard" />
          </Menu.Item>
        </Menu.ItemGroup>

        {/** PLANTS */}

        <Menu.ItemGroup key="plants" title={<IntlMessages id="iot_controls" />}>
          <Menu.Item
            key="/plants"
            icon={<Rss size={14} className="transform-bug" />}
          >
            <IntlMessages id="plant_station" />
          </Menu.Item>
          <Menu.Item
            key="/assets"
            icon={<HardDrive size={14} className="transform-bug" />}
          >
            <IntlMessages id="assets" />
          </Menu.Item>
          <Menu.Item
            key="/device-connects"
            icon={<Share2 size={14} className="transform-bug" />}
          >
            <IntlMessages id="device_connects" />
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="contents" title={<IntlMessages id="contents" />}>
          <Menu.Item
            key="/banners"
            icon={<Tag size={14} className="transform-bug" />}
          >
            <IntlMessages id="banners" />
          </Menu.Item>
          <Menu.Item
            key="/announcements"
            icon={<Airplay size={14} className="transform-bug" />}
          >
            <IntlMessages id="announcements" />
          </Menu.Item>
          <Menu.Item
            key="/productandservices"
            icon={<Codepen size={14} className="transform-bug" />}
          >
            <IntlMessages id="product_and_service" />
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="reports" title={<IntlMessages id="reports" />}>
          <Menu.Item
            key="/energy-trend-reports"
            icon={<LineChartOutlined size={14} className="transform-bug" />}
          >
            <IntlMessages id="energy_trend_reports" />
          </Menu.Item>
          <Menu.Item
            key="/power-meter-reports"
            icon={<Book size={14} className="transform-bug" />}
          >
            <IntlMessages id="power_meter_reports" />
          </Menu.Item>
          <Menu.Item
            key="/energy-history-reports"
            icon={<ThunderboltOutlined size={14} className="transform-bug" />}
          >
            <IntlMessages id="energy_history_reports" />
          </Menu.Item>
          <Menu.Item
            key="/notification-reports"
            icon={<WarningOutlined size={14} className="transform-bug" />}
          >
            <IntlMessages id="notification_reports" />
          </Menu.Item>
        </Menu.ItemGroup>

        {/** Account Manager */}
        <Menu.ItemGroup
          key="settings_group"
          title={<IntlMessages id="settings" />}
        >
          <Menu.Item
            key="/role-groups"
            icon={<Shield size={14} className="transform-bug" />}
          >
            <IntlMessages id="role_groups" />
          </Menu.Item>

          <Menu.Item
            key="/accounts"
            icon={<Users size={14} className="transform-bug" />}
          >
            <IntlMessages id="accounts" />
          </Menu.Item>
        </Menu.ItemGroup>

        {/** Rating Price */}
      </Menu>
    </AdminSlider>
  );
};

export default memo(AdminMenus);

const AdminSlider = styled(Sider)`
  .app-logo {
    height: 64px;
  }
  ul.ant-menu {
    margin-top: 0px !important;
  }
`;
