import api from "./api";

const DEVICE_CONNECT_URL = "/device-connects";

export const getDeviceConnects = async (filter) => {
  return api
    .get(DEVICE_CONNECT_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createDeviceConnect = async (data) => {
  return api.post(DEVICE_CONNECT_URL, data).then((res) => res.data);
};

export const editDeviceConnect = async (hashId) => {
  return api.get(`${DEVICE_CONNECT_URL}/${hashId}`).then((res) => res.data);
};

export const updateDeviceConnect = async (hashId, data) => {
  return api
    .put(`${DEVICE_CONNECT_URL}/${hashId}`, data)
    .then((res) => res.data);
};

export const deviceOptions = async () => {
  return api.get(`${DEVICE_CONNECT_URL}/optionals`).then((res) => res.data);
};
