import { memo } from "react";
import { Row, Col } from "antd";

const CardBoxs = ({ title, desc, values, color, icon }) => {
  return (
    <div className={`card p-3 ${color}`}>
      <Row>
        <Col sm={6} className="text-center mt-1">
          {icon}
        </Col>
        <Col sm={12}>
          <h1 className="topic">{title ?? `Title Box`}</h1>
          <small>{desc ?? `Description boxs`}</small>
        </Col>
        <Col sm={6} className="text-end">
          <h1 className="numuric">{values ?? `00`}</h1>
        </Col>
      </Row>
    </div>
  );
};

export default memo(CardBoxs);
