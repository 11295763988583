import { useSelector } from "react-redux";
import AuthUserSelector from "redux/auth-user/selector";

function useConfigLocale(...constant) {
  const locale = useSelector(AuthUserSelector.locale);
  if (!constant?.length) {
    return [];
  }
  return constant.map((c) => ({
    label:
      locale === "en" ? c.labelEn : locale === "th" ? c.labelTh : c.labelZh,
    value: c.code,
  }));
}

export default useConfigLocale;
