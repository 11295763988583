import { ConfigProvider } from "antd";
import { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import AuthSelector from "redux/auth-user/selector";
import AppLocale from "lng-provider";
import localeTH from "antd/lib/locale/th_TH";
import localeEN from "antd/lib/locale/en_US";
import localeZH from "antd/lib/locale/zh_CN";

const antdLocales = {
  th: localeTH,
  en: localeEN,
  zh: localeZH,
};

const Providers = (props) => {
  const userLocale = useSelector(AuthSelector.locale);
  const currentAppLocale = AppLocale[userLocale];
  const antdLocale = antdLocales[userLocale];
  return (
    <ConfigProvider locale={antdLocale}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
        textComponent={Fragment}
      >
        {props.children}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default Providers;
