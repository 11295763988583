import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const DeviceAndConnectManagements = LazyLoad(
  lazy(() => import("./DeviceAndConnectManagement"))
);
const DeviceAndConnectMaintain = LazyLoad(
  lazy(() => import("./DeviceAndConnectMaintain"))
);

const DeviceAndConnectRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={DeviceAndConnectMaintain} />
      <Route path={`${path}/:hashId`} component={DeviceAndConnectMaintain} />
      <Route path={`${path}`} component={DeviceAndConnectManagements} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(DeviceAndConnectRoutes);
