import api from "./api";

const ASSET_URL = "/assets";

export const getAssets = async (filter) => {
  return api
    .get(ASSET_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createAssets = async (data) => {
  return api.post(ASSET_URL, data).then((res) => res.data);
};

export const editAsset = async (hashId) => {
  return api.get(`${ASSET_URL}/${hashId}`).then((res) => res.data);
};

export const updateAsset = async (hashId, data) => {
  return api.put(`${ASSET_URL}/${hashId}`, data).then((res) => res.data);
};

export const optionalAssets = async () => {
  return api.get(`${ASSET_URL}/optionals`).then((res) => res.data);
};
