import { memo } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Dashboard from "./index";
import NotFoundPage from "../NotFound";

const DashboardRouters = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/`} component={Dashboard} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(DashboardRouters);
