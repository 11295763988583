import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { isEmpty } from 'lodash'

const MySwal = withReactContent(Swal)

export const sweetAlert = async ({
    title, 
    message = undefined, 
    icon = undefined, 
    html = undefined
}) => {
    if(!isEmpty(html)){
        return MySwal.fire({
            title: <strong>{title}</strong>,
            html: html,
            icon: icon
        });
    }

    return MySwal.fire({
        title: <strong>{title}</strong>,
        html: <i>{message}</i>,
        icon: icon
    });
}