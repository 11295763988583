import { memo } from "react";
import { Layout } from "antd";
import dayjs from "dayjs";

const { Footer } = Layout;

const AdminFooter = () => {
  return (
    <Footer className="d-flex justify-content-center">
      {dayjs().format("YYYY")} © Develop by Enserv Power
    </Footer>
  );
};

export default memo(AdminFooter);
