import antdTH from "antd/lib/locale-provider/th_TH";
import thMessages from "../locales/th-TH.json";
import { flattenMessages } from "../index";

const thLang = {
  messages: {
    ...flattenMessages(thMessages)
  },
  antd: antdTH,
  locale: 'th-TH'
}

export default thLang;
