import api from "./api";

const PLANT_URL = "/plants";

// get plants managements
export const getPlants = async (filter) => {
  return api
    .get(PLANT_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createPlant = async (data) => {
  return api.post(PLANT_URL, data).then((res) => res.data);
};

export const editPlant = async (id) => {
  return api.get(`${PLANT_URL}/${id}`).then((res) => res.data);
};

export const updatePlant = async (id, data) => {
  return api.put(`${PLANT_URL}/${id}`, data).then((res) => res.data);
};

// export const updatePlantGEO = async (id, data) => {
//   return api
//     .put(`${PLANT_URL}/update-plant-geo/${id}`, data)
//     .then((res) => res.data);
// };

// export const getCurrentGPS = async (id) => {
//   return api.get(`${PLANT_URL}/geo-plant-id/${id}`).then((res) => res.data);
// };

// export const updatePlantDevices = async (id, data) => {
//   return api
//     .post(`${PLANT_URL}/update-plantdevices/${id}`, data)
//     .then((res) => res.data);
// };

// export const getPlantDevice = async (id) => {
//   return api.get(`${PLANT_URL}/plantdevice/${id}`).then((res) => res.data);
// };

// export const getPlantImages = async (id) => {
//   return api.get(`${PLANT_URL}/images/${id}`).then((res) => res.data);
// };

export const getPlantControls = async () => {
  return api.get(`${PLANT_URL}-controls`).then((res) => res.data);
};

export const getPlantAsset = async (id) => {
  return api.get(`${PLANT_URL}-assets/${id}`).then((res) => res.data);
};
