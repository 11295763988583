import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const PlantMaintain = LazyLoad(lazy(() => import("./PlantMaintains")));
const PlantManagement = LazyLoad(lazy(() => import("./PlantManagements")));

const PlantRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={PlantMaintain} />
      <Route path={`${path}/:hashId`} component={PlantMaintain} />
      <Route path={`${path}`} component={PlantManagement} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(PlantRoutes);
