import { memo } from "react";
import MainLayout from "layout/MainLayout";
import Providers from "./Providers";

const Energymart = () => {
  return (
    <Providers>
      <MainLayout />
    </Providers>
  );
};

export default memo(Energymart);
