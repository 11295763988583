import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const ProductAndServiceManagements = LazyLoad(
  lazy(() => import("./ProductAndServiceManagements"))
);
const ProductAndServiceMaintain = LazyLoad(
  lazy(() => import("./ProductAndServiceMaintain"))
);

const ProductAndServiceArticle = LazyLoad(
  lazy(() => import("./ProductAndServiceMaintain/Articles"))
);

const ProductAndServiceRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={ProductAndServiceMaintain} />
      <Route
        path={`${path}/article/:hashId`}
        component={ProductAndServiceArticle}
      />
      <Route path={`${path}/:hashId`} component={ProductAndServiceMaintain} />
      <Route path={`${path}`} component={ProductAndServiceManagements} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(ProductAndServiceRoutes);
