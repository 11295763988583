import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const AssetManagements = LazyLoad(lazy(() => import("./AssetManagements")));
const AssetMaintain = LazyLoad(lazy(() => import("./AssetMaintains")));

const AssetmentRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={AssetMaintain} />
      <Route path={`${path}/:hashId`} component={AssetMaintain} />
      <Route path={`${path}`} component={AssetManagements} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(AssetmentRoutes);
