import styled from 'styled-components'


const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: var(--blue-main);
    text-transform: uppercase;
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) { 

  }
  @media (min-width: 768px) and (max-width: 991px) { 
    
  }
  @media (max-width: 767px) {

  }
  @media (max-width: 480px) {
    display: block;
  }
`;

export default HeaderTitle