import { memo } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import LoginPage from "./LoginPage";
import { getAccessToken } from "../services";

// import components page
import DashboardRoutes from "./Dashboards/DashboardRouters";
import AssetmentRoutes from "./Assets/AssetRoutes";
import DeviceAndConnectRoutes from "./DeviceAndConnects/DeviceAndConnectRoutes";
import PlantRoutes from "./Plants/PlantRoutes";
import AnnouncementRoutes from "./Announcements/AnnouncementRoutes";
import ProductAndServiceRoutes from "./ProductAndServices/ProductAndServiceRoutes";
import RoleGroupRoutes from "./RoleGroups/RoleGroupRoutes";
import AccountRoutes from "./Accounts/AccountRoutes";
import BannersRoutes from "./Banners/BannersRoutes";
import EnergyHistoryRoutes from "./Reports/EnergyHistoryReports/EnergyHistoryRoutes";
import EnergyTrendRoutes from "./Reports/EnergyTrendReports/EnergyTrendRoutes";
import PowerMeterRoutes from "./Reports/PowerMeterReports/PowerMeterRoutes";
import NotificationRoutes from "./Reports/NotificationReports/NotificationRoutes";

const privateRoute = () => (
  <Switch>
    <Route path={`/dashboard`} component={DashboardRoutes} />
    <Route path={`/assets`} component={AssetmentRoutes} />
    <Route path={`/device-connects`} component={DeviceAndConnectRoutes} />
    <Route path={`/plants`} component={PlantRoutes} />
    <Route path={`/announcements`} component={AnnouncementRoutes} />
    <Route path={`/productandservices`} component={ProductAndServiceRoutes} />
    <Route path={`/role-groups`} component={RoleGroupRoutes} />
    <Route path={`/accounts`} component={AccountRoutes} />
    <Route path={`/banners`} component={BannersRoutes} />
    <Route path={`/energy-history-reports`} component={EnergyHistoryRoutes} />
    <Route path={`/energy-trend-reports`} component={EnergyTrendRoutes} />
    <Route path={`/power-meter-reports`} component={PowerMeterRoutes} />
    <Route path={`/notification-reports`} component={NotificationRoutes} />
    <Route path={`/*`} component={NotFound} />
  </Switch>
);

const publicRoute = () => (
  <Switch>
    <Route path="/signin" component={LoginPage} />
    <Route component={LoginPage} />
  </Switch>
);

const Routes = () => {
  const token = getAccessToken();
  return token ? privateRoute() : publicRoute();
};

export default memo(Routes);
