import { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, Button, Spin } from "antd";
import { LogIn, User } from "react-feather";
import { useIntl } from "react-intl";
import { login } from "../../services/AuthService";
import { sweetAlert } from "components/Swal";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setLoading(false)
  }, [])

  const onSubmit = async (values) => {
    setLoading(true)
    await login(values?.username, values?.password).catch((err) => sweetAlert({
      title: intl.formatMessage({ id: "warning" }),
      message: intl.formatMessage({ id: 'account_invalid' }),
      icon: "warning",
    }))
    setLoading(false)
  }

  return (
    <div className="bg-login" style={{ height: '100%'}}>
      <div className="container" style={{ height: '100%'}}>
        <div className="row justify-content-center" style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
        }}>
          <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4">
            <Spin spinning={loading}>
              <CardLogin>
                <div className="ImgIcon">
                  <User size="30" />
                </div>

                <h3 className="text-center mb-3">LogIn</h3>
                <Form name="normal_login" className="login-form" onFinish={onSubmit}>
                  <Form.Item name="username">
                    <Input placeholder="Username" size="large" />
                  </Form.Item>
                  <Form.Item name="password">
                    <Input type="Password" placeholder="Password" size="large" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button w-100 button-primary"
                      size="large"
                      icon={<LogIn size={26} className="pe-2 transform-bug" />}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </CardLogin>
              </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Login);

const CardLogin = styled.div`
  background: rgba(255, 255, 255, 0.3);
  padding:3em 3em 2em 3em;
  width: 100%;
  border-radius: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgb(0 0 0/20%);
  transition: all 0.2s ease-in-out;
  position relative;

  @media (max-width: 767px) {
    padding:2em 2em 1em 2em;
  }
  @media (max-width: 480px) {
    padding:2em 2em 1em 2em;
  }

  .ImgIcon {
    background: var(--blue-main-gradient);
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30rem;
    margin: 0 auto;
    top:-40px;
    left:0px;
    right:0px;
    position: absolute;
    svg {
      color:white;
    }
  }

  .ant-input {
    background-color: rgba(255, 255, 255, 0.6) !important;
    border-radius: 5px;
  }
`;
