import enLang from "./entries/en-US";
import thLang from "./entries/th-TH";
import zhLang from "./entries/zh-CN";

const AppLocale = {
  en: enLang,
  zh: zhLang,
  th: thLang,
};

export default AppLocale;

export function flattenMessages(nestedMessages, prefix = "") {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}
