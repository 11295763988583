import { memo, useState } from "react";
import { Breadcrumb, Spin, Row, Col } from "antd";
import { Card, HeaderTitle } from "components/styled-components";
import { useMount } from "hooks";

import { Rss, Target, Flag, HardDrive } from "react-feather";

import CardBoxs from "components/CardBoxs";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);

  useMount(() => void fetchDataDefault());

  const fetchDataDefault = async () => {};

  return (
    <section className="bg-main">
      <Card>
        <HeaderTitle>
          <div>
            <h1>Dashboard</h1>
            <Breadcrumb className="mb-3">
              <Breadcrumb.Item>Release</Breadcrumb.Item>
              <Breadcrumb.Item>Version</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </HeaderTitle>
        <Spin spinning={loading}>
          <Row gutter={16}>
            <Col lg={6} className="mb-3">
              <CardBoxs
                title={`Plants`}
                desc={`summary plant stations`}
                icon={<Rss size={52} />}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <CardBoxs
                title={`Devices`}
                desc={`summary plant stations`}
                icon={<Target size={52} />}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <CardBoxs
                title={`Anouncements`}
                desc={`summary plant stations`}
                icon={<Flag size={52} />}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <CardBoxs
                title={`Products`}
                desc={`summary plant stations`}
                icon={<HardDrive size={52} />}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
    </section>
  );
};

export default memo(Dashboard);
