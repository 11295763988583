import api from "./api";

const ACCOUNTS_URL = "/accounts";

export const getAccounts = async (filter) => {
  return api
    .get(ACCOUNTS_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createAccount = async (data) => {
  return api.post(ACCOUNTS_URL, data).then((res) => res.data);
};

export const editAccount = async (hashId) => {
  return api.get(`${ACCOUNTS_URL}/${hashId}`).then((res) => res.data);
};

export const updateAccount = async (hashId, data) => {
  return api.put(`${ACCOUNTS_URL}/${hashId}`, data).then((res) => res.data);
};

export const patchChangePassword = async (hashId, data) => {
  return api
    .put(`${ACCOUNTS_URL}/change-password/${hashId}`, data)
    .then((res) => res.data);
};

export const getAccountCustomers = async () => {
  return api.get(`${ACCOUNTS_URL}/getCustomers`).then((res) => res.data);
};
