import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import store, { history } from "redux/store";
import { Energymart } from "layout";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/index.scss";

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={Energymart} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
