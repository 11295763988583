import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const AnnouncementManagements = LazyLoad(
  lazy(() => import("./AnnouncementManagements"))
);
const AnnouncementMaintain = LazyLoad(
  lazy(() => import("./AnnouncementMaintain"))
);

const AnnouncementRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={AnnouncementMaintain} />
      <Route path={`${path}/:hashId`} component={AnnouncementMaintain} />
      <Route path={`${path}`} component={AnnouncementManagements} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(AnnouncementRoutes);
