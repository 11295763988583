import api from "./api";

const PRODUCTANDSERVICE_URL = "/productandservices";

export const getProductAndServices = async (filter) => {
  return api
    .get(PRODUCTANDSERVICE_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createProductAndServices = async (data) => {
  return api.post(PRODUCTANDSERVICE_URL, data).then((res) => res.data);
};

export const editProductAndService = async (hashId) => {
  return api.get(`${PRODUCTANDSERVICE_URL}/${hashId}`).then((res) => res.data);
};

export const updateProductAndService = async (hashId, data) => {
  return api
    .put(`${PRODUCTANDSERVICE_URL}/${hashId}`, data)
    .then((res) => res.data);
};

export const getShowProductAndservice = async (hashId) => {
  return api
    .get(`${PRODUCTANDSERVICE_URL}/article/${hashId}`)
    .then((res) => res.data);
};

export const setTemplateProductAndService = async (hashId, data) => {
  return api
    .post(`${PRODUCTANDSERVICE_URL}/article/${hashId}`, data)
    .then((res) => res.data);
};

export const updateArticleProductAndService = async (slug, data) => {
  return api
    .put(`${PRODUCTANDSERVICE_URL}/article/${slug}`, data)
    .then((res) => res.data);
};

export const deleteArticleProductAndService = async (hashId) => {
  return api
    .delete(`${PRODUCTANDSERVICE_URL}/article/${hashId}`)
    .then((res) => res.data);
};

export const getContentArticles = async (hashId) => {
  return api
    .get(`${PRODUCTANDSERVICE_URL}/contents/${hashId}`)
    .then((res) => res.data);
};
