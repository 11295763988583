import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authUser from 'redux/auth-user/reducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    [authUser.name]: authUser.reducer
  });

export default createRootReducer;
