import api from "./api";

const BANNERS_URL = "/banners";

export const getBanners = async (filter) => {
  return api
    .get(BANNERS_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createBanner = async (data) => {
  return api.post(BANNERS_URL, data).then((res) => res.data);
};

export const editBanner = async (hashId) => {
  return api.get(`${BANNERS_URL}/${hashId}`).then((res) => res.data);
};

export const updateBanner = async (hashId, data) => {
  return api
    .put(`${BANNERS_URL}/${hashId}`, data)
    .then((res) => res.data);
};