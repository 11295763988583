import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const BannersManagements = LazyLoad(
  lazy(() => import("./BannersManagements"))
);
const BannersMaintain = LazyLoad(
  lazy(() => import("./BannersMaintain"))
);

const BannersRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={BannersMaintain} />
      <Route path={`${path}/:hashId`} component={BannersMaintain} />
      <Route path={`${path}`} component={BannersManagements} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(BannersRoutes);
