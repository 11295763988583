import antdZH from 'antd/lib/locale-provider/zh_CN';
import zhMessages from '../locales/zh-CN.json';
import { flattenMessages } from '../index';

const ZhLang = {
  messages: {
    ...flattenMessages(zhMessages)
  },
  antd: antdZH,
  locale: 'zh-CN'
};
export default ZhLang;
