function exportExcel(buffer, reportName) {
  const blob = new Blob([buffer]);
  console.log(blob);
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = `${reportName}.xlsx`;
  link.click();
  link.remove();
}

export default exportExcel;
