import { createElement, memo, useMemo } from "react";
import { Dropdown, Layout, Menu, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useToggleIndex } from "hooks";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import LangSwitch from "components/LangSwitch";
import AuthUserSelector from "redux/auth-user/selector";
import { changeLocale } from "redux/auth-user/reducer";
import { logout, getUserInfo } from "../services";

const { Header } = Layout;

const AdminHeader = ({ collapsed, toggleMenu }) => {
  const dispatch = useDispatch();
  const userLocale = useSelector(AuthUserSelector.locale);
  const [Arrow, toggleArrow] = useToggleIndex(
    [CaretDownOutlined, CaretUpOutlined],
    0
  );

  const userInfo = getUserInfo();

  const menu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item>
          <Space align="baseline" onClick={logout}>
            <PoweroffOutlined className="icon-up" />
            <span className="ms-1">Logout</span>
          </Space>
        </Menu.Item>
      </Menu>
    );
  }, []);

  const onLangChange = (locale) => dispatch(changeLocale(locale));

  return (
    <Header className="d-flex align-items-center justify-content-between px-4">
      {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggleMenu,
      })}

      <Space size="large" align="start">
        <LangSwitch value={userLocale} onChange={onLangChange} />
        <Dropdown
          overlay={menu}
          placement="bottomCenter"
          onVisibleChange={toggleArrow}
          trigger={["click"]}
        >
          <div className="d-flex align-items-center pointer mr-4">
            <div className="avatar-cover bg-user-icon mr-2"></div>
            <div className="user-name mr-2">
              <span className="mr-2" style={{ fontSize: "14px" }}>
                {userInfo?.displayName || userInfo?.username}
              </span>
            </div>
            <Arrow
              className="arrow-icon ms-2 mt-1"
              style={{ fontSize: "12px" }}
            />
          </div>
        </Dropdown>
      </Space>
    </Header>
  );
};

export default memo(AdminHeader);
