import React, { memo } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "components/IntlMessages";

const NotFoundPage = () => {
  return (
    <Result
      status="404"
      title="404 NOT FOUND PAGE"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/dashboard">
          <Button type="primary">
            <IntlMessages id="back_to_home_page" />
          </Button>
        </Link>
      }
    />
  );
};

NotFoundPage.propTypes = {};

export default memo(NotFoundPage);
