import styled from "styled-components";

const Card = styled.div`
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 18px -1px rgba(31, 134, 145, 0.23);
  -moz-box-shadow: 0px 0px 18px -1px rgba(31, 134, 145, 0.23);
  box-shadow: 0px 0px 18px -1px rgba(31, 134, 145, 0.23);
  padding: 30px;
  background-color: white;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export default Card;
