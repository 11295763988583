import api from "./api";

const ANNOUNCEMENTS_URL = "/announcements";

export const getAnnouncements = async (filter) => {
  return api
    .get(ANNOUNCEMENTS_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createAnnouncements = async (data) => {
  return api.post(ANNOUNCEMENTS_URL, data).then((res) => res.data);
};

export const editAnnouncement = async (hashId) => {
  return api.get(`${ANNOUNCEMENTS_URL}/${hashId}`).then((res) => res.data);
};

export const updateAnnouncement = async (hashId, data) => {
  return api
    .put(`${ANNOUNCEMENTS_URL}/${hashId}`, data)
    .then((res) => res.data);
};
