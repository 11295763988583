import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const sweetConfirm = async (title, message) => {    
    return MySwal.fire({
        title: <strong>{title}</strong>,
        html: <i>{message}</i>,
        showCancelButton: true,
      }).then((props) => {
        return props
      });
}