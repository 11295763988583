import api from "./api";

const PLANTHIERARCHY_URL = "/plant-hierarchies";
const PLANT_PERMISSIONS_URL = "/plant-permissions";

// get plants managements
// export const getPlants = async (filter) => {
//   return api
//     .get(PLANTHIERARCHY_URL, {
//       params: filter,
//     })
//     .then((res) => res.data);
// };

export const createPlantHierarchy = async (plantId, data) => {
  return api
    .post(`${PLANTHIERARCHY_URL}/${plantId}`, data)
    .then((res) => res.data);
};

export const showPlatHierarchies = async (plantId) => {
  return api
    .get(`${PLANTHIERARCHY_URL}/show/${plantId}`)
    .then((res) => res.data);
};

export const upPlantPermissions = async (plantId, data) => {
  return api
    .post(`${PLANT_PERMISSIONS_URL}/${plantId}`, data)
    .then((res) => res.data);
};

export const showPlatPermissions = async (plantId) => {
  return api
    .get(`${PLANT_PERMISSIONS_URL}/show/${plantId}`)
    .then((res) => res.data);
};
// export const editPlant = async (id) => {
//   return api.get(`${PLANT_URL}/${id}`).then((res) => res.data);
// };

// export const updatePlant = async (id, data) => {
//   return api.put(`${PLANT_URL}/${id}`, data).then((res) => res.data);
// };
