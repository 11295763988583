import api from "./api";

const ROLE_GROUP_URL = "/role-groups";

export const getRoleGroups = async (filter) => {
  return api
    .get(ROLE_GROUP_URL, {
      params: filter,
    })
    .then((res) => res.data);
};

export const createRoleGroup = async (data) => {
  return api.post(ROLE_GROUP_URL, data).then((res) => res.data);
};

export const editRoleGroup = async (hashId) => {
  return api.get(`${ROLE_GROUP_URL}/${hashId}`).then((res) => res.data);
};

export const updateRoleGroup = async (hashId, data) => {
  return api.put(`${ROLE_GROUP_URL}/${hashId}`, data).then((res) => res.data);
};

export const optionalRoleGroup = async () => {
  return api.get(`${ROLE_GROUP_URL}/optionals`).then((res) => res.data);
};
