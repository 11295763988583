import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../../NotFound";
import LazyLoad from "components/LazyLoad";

const ReportPowerMeterRoutes = LazyLoad(lazy(() => import("./index")));

const PowerMeterRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} component={ReportPowerMeterRoutes} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(PowerMeterRoutes);
