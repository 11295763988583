import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import rootSaga from "./root-saga";
import createRootReducer from "./root-reducer";
// import { signOut } from "./reducers/auth";

export const history = createBrowserHistory();

// Middleware
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

// Root Reducer
const appReducer = createRootReducer(history);
const rootReducer = (state, action) => {
  // if (action.type === signOut.type) {
  //   state = undefined
  // }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      // serializableCheck: false,
    }),
    sagaMiddleware,
    routeMiddleware,
  ],
});
sagaMiddleware.run(rootSaga);

export default store;
