import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pending: false,
  locale: 'en',
}

const slice = createSlice({
  initialState,
  name: 'authUser',
  reducers: {
    signIn: (state, action) => {
      state.pending = true
    },
    signInSuccess: (state, { payload }) => {
      state.pending = false;
      state.accessToken = payload.token;
    },
    signInFailure: (state, { payload }) => {
      state.pending = false;
      state.error = payload;
    },
    signOut: () => initialState,
    changeLocale: (state, { payload }) => {
      state.locale = payload
    },
  }
});

export const { signIn, signInSuccess, signInFailure, signOut, changeLocale } = slice.actions;

export default slice;
