import { memo, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFoundPage from "../NotFound";
import LazyLoad from "components/LazyLoad";

const AccountManagements = LazyLoad(lazy(() => import("./AccountManagements")));
const AccountMaintain = LazyLoad(lazy(() => import("./AccountMaintain")));

const AccountRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`} component={AccountMaintain} />
      <Route path={`${path}/:hashId`} component={AccountMaintain} />
      <Route path={`${path}`} component={AccountManagements} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default memo(AccountRoutes);
