import { useState } from 'react'

const useToggleIndex = (data, initDefault) => {

    const [index, setIndex] = useState(initDefault)

    function toggle() {
        setIndex(i => (i+1) % data.length)
    }

    return [data[index], toggle];
}

export default useToggleIndex;